import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';


const REMAP_USER_IDENTITY = gql`
mutation uploadRemapUsersCSV($file: String) {
    uploadRemapUsersCSV(file: $file) {
      success
      message
    }
  }`;

export const RemapUsers = ({ pushAlert, ...props }) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const [remapUserIdentity, { loading }] = useMutation(
    REMAP_USER_IDENTITY, {
      variables: {
        file: {
          file
        }
      },
      onCompleted: (response) => {
        setModalVisible(false);
        const message = response.uploadRemapUsersCSV.message;
        if (response.uploadRemapUsersCSV.success === false) {
          pushAlert(message, false, 'RemapError');
        } else {
          pushAlert(message, true, 'RemapSuccess');
        }
      },
      onError: (error) => {
        setModalVisible(false);
        pushAlert(error.message, false, 'RemapServerError');
      }
    }
  );

  const handleUpload = async () => {
    if (!file) {
      setModalVisible(false);
      return pushAlert('Please select a CSV file', false, 'FileNotFound');
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const base64String = reader.result.split(',')[1];
      try {
        await remapUserIdentity({
          variables: { file: base64String }
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      
      <Button className="ant-btn-lg" style={{marginLeft: '5px'}} loading={loading} onClick={() => setModalVisible(true)} {...props} type="primary">
        Remap Users
      </Button>
      <Modal
        title="Remap Users"
        visible={modalVisible}
        onOk={handleUpload}
        onCancel={() => setModalVisible(false)}
        confirmLoading={loading}
      >
        <input className="ant-btn-lg" type="file" accept=".csv" onChange={handleFileChange} />
      </Modal>
    </>
  );
};