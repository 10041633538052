import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { colour } from '../styles/variables';

const Footer = () => (
  <TeamsFooter>
    <span>
      <Trademark>© 2018 A Cloud Guru Ltd.</Trademark>
      <a href="https://legal.pluralsight.com/policies" target="_blank" rel="noreferrer">
        Terms of Use
      </a>
      <a href="https://legal.pluralsight.com/policies?name=privacy-notice" target="_blank" rel="noreferrer">
        Privacy Notice
      </a>
    </span>
    Keep being awesome, Cloud Gurus
  </TeamsFooter>
);

const TeamsFooter = styled.span`
  display: flex;
  justify-content: space-between;
  padding: 0 60px 24px 60px;
  font-size: 13px;
  color: ${lighten(0.1, colour.gray600)};

  a {
    padding: 0 12px 0 0;
    text-decoration: underline;
    color: ${lighten(0.1, colour.gray600)};
  }
`;

const Trademark = styled.span`
  font-weight: 450;
  padding: 0 20px 0 0;
`;

export { Footer };
